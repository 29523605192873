import React from 'react'
import { UserProvider } from './stores/UserStore'
import Router from './Router'
import { Analytics, Auth } from 'aws-amplify'
import awsConfig from './aws'
import { toast, Zoom } from 'react-toastify'
import './i18n'
import theme from './utils/theme'
import { ThemeProvider } from 'styled-components'

toast.configure({
  autoClose: 4000,
  draggable: false,
  transition: Zoom,
  hideProgressBar: true,
  closeButton: false
})

Auth.configure(awsConfig)
Analytics.configure({ disabled: true })

const App = () => {
  if (process.env.REACT_APP_PROJECT === 'redirect') {
    return (
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router />
      </UserProvider>
    </ThemeProvider>
  )
}

export default App
