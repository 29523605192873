import { useEffect, useContext } from 'react'
import queryString from 'query-string'
import { UserContext } from '../stores/UserStore'
import Cookies from 'js-cookie'

const Home = ({ location }) => {

  const { user } = useContext(UserContext)

  useEffect(() => {
    let redirectPath = queryString.parse(location.search).redirectTo || process.env.REACT_APP_HOME_URL
    const lastUserId = Cookies.get(`${user.keyPrefix}.LastAuthUser`)       
    if (lastUserId) {
      if (redirectPath.indexOf(process.env.REACT_APP_DOMAIN) === -1) {
        const refreshToken = Cookies.get(`${user.keyPrefix}.${lastUserId}.refreshToken`)
        const deviceKey = Cookies.get(`${user.keyPrefix}.${lastUserId}.deviceKey`)        

        redirectPath += '?kp=' + user.keyPrefix + 
                          '&lu=' + user.username + 
                          '&rt=' + refreshToken + 
                          '&dk=' + deviceKey + 
                          ''
      }
    }
    window.location.assign(redirectPath)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default Home