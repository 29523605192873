import React, { useContext, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundImage from '../assets/images/background.jpg'
import { LoadingOverlay } from '../components/common'
import { UserContext } from '../stores/UserStore'

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background};
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
`

const Logout = (props) => {
  const { signOut } = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    signOut().then(() => {
      history.replace('/login'+props.location.search)
    }).catch(err => {
      history.replace('/login'+props.location.search)
    })
  }, [])

  return (
    <Background>
      <LoadingOverlay borderRadius="card" visible={true} />
    </Background>
  )
}

export default Logout