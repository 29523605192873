import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import { LoadingOverlay } from '../components/common'
import axios from 'axios'

const initialState = {
  user: null,
  accessToken: '',
  idToken: '',
  refreshToken: ''
}

const KEY_PREFIX_PROVIDER = 'CognitoIdentityServiceProvider.keyPrefix'

export const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [authState, setAuthState] = useState('LOGIN')
  const [accessToken, setAccessToken] = useState('')
  const [idToken, setIdToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')  
  
  const [isLoading, setLoading] = useState(true)

  const signIn = (email, password, rememberMe) => new Promise((resolve, reject) => {
    Cookies.set('rememberMe', rememberMe, { expires: 365, domain: process.env.REACT_APP_DOMAIN })

    Auth.signIn(email, password).then(loggedUser => {
      if (loggedUser) {
        setUser(loggedUser)
        if (loggedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setAuthState('NEW_PASSWORD_REQUIRED')
          resolve(false)
        }
        else {
          loadSession(loggedUser);
          Cookies.set(`${KEY_PREFIX_PROVIDER}`, loggedUser.keyPrefix, { domain: process.env.REACT_APP_DOMAIN })          
          Cookies.set('signedIn', true, { domain: process.env.REACT_APP_DOMAIN })
          setAuthState('SIGNED_IN')
        }
      }
    }).catch(error => {
      reject(error)
    })
  })

  const changePassword = (newPassword) => new Promise((resolve, reject) => {
    Auth.completeNewPassword(user, newPassword).then(loggedUser => {
      loadSession(loggedUser);

      setAuthState('SIGNED_IN')
      registryLogOld('change_password' , 'login')
      registryLog('login', "click", "change_password")
      resolve()
    }).catch(error => {
      reject(error)
    })
  })


  const loadSession = (loggedUser) => {
    setUser(loggedUser)

    setIdToken(loggedUser.signInUserSession.idToken.jwtToken)
    setAccessToken(loggedUser.signInUserSession.accessToken.jwtToken)
    setRefreshToken(loggedUser.signInUserSession.refreshToken.token)

  }  

  const clearSession = () => {
    setUser(null)
    setIdToken('')
    setAccessToken('')
    setRefreshToken('')
  }

  const resetAuthState = () => {
    clearSession();
    setAuthState('LOGIN')
  }

  const forgotPassword = () => {
    setAuthState('FORGOT_PASSWORD')
  }

  const sendRecoveryCode = username => new Promise((resolve, reject) => {
    Auth.forgotPassword(username).then(data => {
      resolve(data)
    }).catch(err => reject(err))
  })

  const recoverPassword = (username, code, newPassword) => new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, newPassword).then(data => {
      resetAuthState()
      resolve(data)
    }).catch(err => reject(err))
  })

  const signOut = async () => {
    try {
      await Auth.signOut()
      clearSession();
      setAuthState(null)
    } catch (e) {
      console.log(e)
    }
  }

  const logInstanceOld = axios.create({
    baseURL: process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT,
  })
  console.log('process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT ' + process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT)
  const registryLogOld = (click, url)  => {
    new Promise((resolve, reject) => {
      console.debug(process.env.REACT_APP_AWS_CLOUDWATCH_ENDPOINT)
      if (user) {
        logInstanceOld.post(
            '/access-ctrl/log',
            {
              type: 'access',
              user: {
                userId: user.attributes.email
              },
              functionality: url,
              application: 'smart',
              message: click
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(response => {
          if (response.status === 200) {
            console.debug('Log salvo')
            resolve()
          }
          else {
            reject(new Error('Couldn\'t retrieve data'))
          }
        })
            .catch(error => {
              reject(error)
            })
      }
    })
  }

  const newLogInstance = axios.create({
    baseURL: process.env.REACT_APP_LOG_ENDPOINT,
  })
  const registryLog = (functionalityDsc, actionDsc, detailDsc)  => {
    new Promise((resolve, reject) => {
      console.debug(process.env)
      if (user) {
        newLogInstance.post(
            '/log',
            {
              type: 'access',
              user: {
                userId: user.username,
                userEmail: user.attributes.email
              },
              url: window.location.href,
              application: 'REPORTS',

              functionality: functionalityDsc,
              action: actionDsc,
              detail: detailDsc
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(response => {
          if (response.status === 200) {
            console.debug('Log salvo')
            resolve()
          }
          else {
            reject(new Error('Couldn\'t retrieve data'))
          }
        })
            .catch(error => {
              reject(error)
            })
      }
    })
  }

  useEffect(() => {
    const hydrate = () => new Promise((resolve, reject) => {
      const shouldGetUser = (Cookies.get('rememberMe') === 'true' || Cookies.get('signedIn') === 'true')
      shouldGetUser ? Auth.currentUserPoolUser().then(async (user) => {
        if (user) {
          loadSession(user);
          Cookies.set(`${KEY_PREFIX_PROVIDER}`, user.keyPrefix, { domain: process.env.REACT_APP_DOMAIN })
          Cookies.set('signedIn', true, { domain: process.env.REACT_APP_DOMAIN })
          setAuthState('SIGNED_IN')
        }
        resolve()
      }).catch(err => {
        reject(err)
      }) : resolve()
    })

    hydrate().catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const renderStore = isLoading ?
    <LoadingOverlay visible={true} />
  :
    <UserContext.Provider value={{
      user,
      accessToken,
      idToken,
      refreshToken,
      authState,
      changePassword,
      forgotPassword,
      sendRecoveryCode,
      recoverPassword,
      resetAuthState,
      signIn,
      signOut,
      registryLog
    }}>
      {children}
    </UserContext.Provider>

  return renderStore
}
