import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Card, FlexView, Button } from '../components/common'
import Logo from '../assets/images/new-logo.png'
import Blocked from '../assets/images/blocked.png'

const Background = styled.div`
  background-image: ${({ theme }) => theme.gradients.main};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// const Copyrights = styled.div`
//   color: ${({ theme }) => theme.colors.gray};
// `

const ErrorCode = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: calc(${({ theme }) => theme.fontSizes.extraLarge} + 27px);
  background-image: ${({ theme }) => theme.gradients.main};
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`

const ErrorWrapper = styled.div`
  background-image: url(${Blocked});
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: center;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const RedirectDomain = (props) => {
  const { t } = useTranslation()

  const open = () => {
    let origin = window.location.origin;
    let appDomanin = `${process.env.REACT_APP_DOMAIN}`;
    let redirectDomanin = `${process.env.REACT_APP_REDIRECT_DOMAIN}`;
    let env = `${process.env.REACT_APP_ENV}`;

    if (origin.indexOf('runningexpert') !== -1) {
      origin = origin.replace('runningexpert', 'tallyvision');
      if (env === 'staging') {
        redirectDomanin = `${process.env.REACT_APP_DOMAIN}`;
      }
    }

    const redirectPath = origin.replace(appDomanin, redirectDomanin)
    window.location.assign(redirectPath)
  }

  return (
    <Background>
      <Card width="90vw" height="80vh" elevation="veryHigh" padding="24px" justifyContent="space-between" alignItems="stretch">
        <img src={Logo} alt="Application Logo" width="160px" />
        <ErrorWrapper>
          <ErrorCode>O endereço mudou!</ErrorCode>
          <FlexView flexDirection="row">
            <Button width="240px" backgroundColor="secondary" color="white" margin="10px 5px" onClick={open} >{t('Abrir Novo Endereço')}</Button>
          </FlexView>
        </ErrorWrapper>
      </Card>
    </Background>
  )
}

export default RedirectDomain